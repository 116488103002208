/* =======
    FONTS
   ======= */
@font-face {
    font-family: "Whitney";
    font-weight: 400;
    font-display: swap;
    src: url("../../static/fonts/whitney-400.woff");
}
@font-face {
    font-family: "Whitney";
    font-weight: 600;
    font-display: swap;
    src: url("../../static/fonts/whitney-600.woff");
}
@font-face {
    font-family: "Whitney";
    font-weight: 700;
    font-display: swap;
    src: url("../../static/fonts/whitney-700.woff");
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

@import "mixins.scss";
@import "colors.scss";

/* ========
    GLOBAL
   ======== */
body {
    height: 100vh;
    margin: 0;
    padding: 0;

    background: url("data:image/svg+xml,%3Csvg width='78' height='39' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23272727' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E"),
        no-repeat $background-gradient, $base;

    @include isMobile() {
        background: no-repeat $background-gradient-mobile, $base;
    }
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background-color: #333333;
}

::-webkit-scrollbar-thumb {
    background-color: #555555;

    &:hover {
        background-color: #5e5e5e;
    }
}

#root {
    min-height: 100%;
    position: relative;
}

#view {
    padding-bottom: 300px;
    @include isMobile() {
        padding-bottom: 250px;
    }
}

.Toastify__progress-bar--dark {
    background-color: #689e64 !important;
}

.Toastify__toast--dark {
    background-color: #424941 !important;
}

.__react_component_tooltip.show {
    opacity: 1 !important;
}

/* ===================
    NON-PAGE ELEMENTS
   =================== */
#info-banner {
    background: linear-gradient(to right, #c9427a, #5f42c9);
    text-align: center;
    font-size: 0.9rem;
    font-weight: 500;

    p {
        margin: 0;
        padding: 4px;
    }

    code {
        color: #ffb7b7;
    }

    :hover {
        cursor: pointer;
    }
}

#nav {
    margin: 0 auto 60px;
    padding: 40px 20px 0;
    max-width: 800px;

    font: 500 15px sans-serif;
    line-height: 40px;
    // border-bottom: 1px dashed #454545;

    a {
        text-decoration: none;
        color: white;
        transition: color .5s ease;

        &:hover {
            color: #ddd;
        }
    }

    label, input {
        display: none;
    }

    // The logo
    >a {
        margin-right: 20px;
        display: flex;
        float: left;
        font-size: 20px;
    }

    ul {
        margin: 0;
        padding: 0;
        
        list-style: none;
    
        li {
            display: inline;
            margin: 0px 20px;
        }
    }

    @include isMobile() {
        >a {
            position: absolute;
        }
    
        label {
            display: block;
            margin-left: 20px;
            text-align: right;
            font-size: 20px;
            color: white;
            text-shadow: black 3px 3px 1px;
        }   
    
        li {
            display: block;
        }
    
        ul {
            display: none;
            margin-top: 15px;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 5px;
        }
        input:checked ~ ul {
            display: flex;
            flex-direction: column;
        }
    }
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15%;
        width: 70%;

        @include isMobile() {
            flex-direction: column;
            padding: 5px 10px 20px;
            width: calc(100% - 20px);
        }

        background-color: #2a2a2a;

        a:not(:first-child) {
            margin-left: 10px;
        }
    }
}

/* ============================
    DEFAULT VALUES AND CLASSES
   ============================ */
p, h1, h2, h3, h4, a, li, label, input[type=submit], .__react_component_tooltip {
    color: white;
    font-family: 'Segoe UI', Geneva, Verdana, sans-serif;
}

h1, h2, h3, h4 {
    font-family: 'Montserrat', 'Segoe UI', Geneva, Verdana, sans-serif;
    color: transparent;
    background-image: $header-gradient;
    background-clip: text;
    -webkit-background-clip: text;
    filter: drop-shadow(-5px 3px  5px rgba(0, 0, 0, 0.5));
}

h1:not(#title>h1) {
    position: relative;
    display: inline;

    &::before {
        content: '';
        position: absolute;
        left: -7px;
        height: 10px;
        width: 10px;
        border-top: 1px solid #aeaeae;
        border-left: 1px solid #aeaeae;
    }

    &::after {
        content: '';
        position: absolute;
        right: -7px;
        bottom: 0;
        height: 10px;
        width: 10px;
        border-bottom: 1px solid #aeaeae;
        border-right: 1px solid #aeaeae;
    }


    // border-top: 3px solid #ecfff3;
    // &::after {
    //     content: '';
    //     display: block;
    //     height: 3px;
    //     width: 75px;
    //     background-color: #ecfff3;
    // }
}

.grey {
    color: #a3a3a3;
}

code {
    color: #ff6d6d;
    .Toastify & {
        color: #d8d8d8;
    }
}

a:not(#nav a, .button) {
    text-decoration: none;
    border-bottom: 2px dashed #aeaeae;

    &:hover {
        border-color: #d8d8d8;
    }
}

.generic-text {
    margin: 0 auto;
    &:not(.no-padding) {
        padding: 0 20px 60px;
    }
    &.top-padding {
        padding-top: 45px;
    }
    width: min(calc(100% - 40px), 1000px);
}

.float-right {
    float: right;
}

.button {
    display: inline-block;
    margin: 10px 7px 0;
    padding: 8px 0;
    min-width: 160px;

    border-radius: 5px;
    border: 1px solid #bfbfbf;
    outline: none;
    color: white;
    background-color: $button;
    // box-shadow: 0 0 10px #050505;
    
    text-align: center;
    line-height: 1rem;
    text-decoration: none;

    &:hover, &:focus {
        border-color: white;
    }

    &:active {
        transform: scale(.96);
    }
}

.boxed {
    padding: 30px;
    background-color: $dark-box;
    border-radius: 5px;
    border: 1px solid #333333;
    // box-shadow: 0 0 8px #131313;
}

.svg-spacer {
    display: block;
    width: 100%;
    height: max(130px, 10vw);

    &.flip {
        transform: rotate(180deg);
    }
}

.sidebar-decoration {
    @media screen and (max-width: 1400px) {
        display: none;
    }
    position: absolute;
    filter: drop-shadow(rgba(0, 0, 0, 0.5) 10px 10px 5px);
    pointer-events: none;
    animation: hovering 3s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
}

@keyframes hovering {
    0% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
