@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap";
@font-face {
  font-family: Whitney;
  font-weight: 400;
  font-display: swap;
  src: url("whitney-400.c6a7adec.woff");
}

@font-face {
  font-family: Whitney;
  font-weight: 600;
  font-display: swap;
  src: url("whitney-600.32e81cd9.woff");
}

@font-face {
  font-family: Whitney;
  font-weight: 700;
  font-display: swap;
  src: url("whitney-700.c52e35f3.woff");
}

body {
  background: url("data:image/svg+xml,%3Csvg width='78' height='39' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23272727' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), radial-gradient(150% 100vh at 100% 0, #27272788 10%, #23232388 40%, #161616) no-repeat, #161616;
  height: 100vh;
  margin: 0;
  padding: 0;
}

@media screen and (width <= 768px) {
  body {
    background: radial-gradient(150% 100vh at 100% 0, #272727, #161616) no-repeat, #161616;
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #333;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5e5e5e;
}

#root {
  min-height: 100%;
  position: relative;
}

#view {
  padding-bottom: 300px;
}

@media screen and (width <= 768px) {
  #view {
    padding-bottom: 250px;
  }
}

.Toastify__progress-bar--dark {
  background-color: #689e64 !important;
}

.Toastify__toast--dark {
  background-color: #424941 !important;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

#info-banner {
  text-align: center;
  background: linear-gradient(to right, #c9427a, #5f42c9);
  font-size: .9rem;
  font-weight: 500;
}

#info-banner p {
  margin: 0;
  padding: 4px;
}

#info-banner code {
  color: #ffb7b7;
}

#info-banner :hover {
  cursor: pointer;
}

#nav {
  max-width: 800px;
  margin: 0 auto 60px;
  padding: 40px 20px 0;
  font: 500 15px / 40px sans-serif;
}

#nav a {
  color: #fff;
  text-decoration: none;
  transition: color .5s;
}

#nav a:hover {
  color: #ddd;
}

#nav label, #nav input {
  display: none;
}

#nav > a {
  float: left;
  margin-right: 20px;
  font-size: 20px;
  display: flex;
}

#nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#nav ul li {
  margin: 0 20px;
  display: inline;
}

@media screen and (width <= 768px) {
  #nav > a {
    position: absolute;
  }

  #nav label {
    text-align: right;
    color: #fff;
    text-shadow: 3px 3px 1px #000;
    margin-left: 20px;
    font-size: 20px;
    display: block;
  }

  #nav li {
    display: block;
  }

  #nav ul {
    background-color: #00000080;
    border-radius: 5px;
    margin-top: 15px;
    display: none;
  }

  #nav input:checked ~ ul {
    flex-direction: column;
    display: flex;
  }
}

#footer {
  width: 100%;
  position: absolute;
  bottom: 0;
}

#footer > div {
  background-color: #2a2a2a;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  padding: 0 15%;
  display: flex;
}

@media screen and (width <= 768px) {
  #footer > div {
    flex-direction: column;
    width: calc(100% - 20px);
    padding: 5px 10px 20px;
  }
}

#footer > div a:not(:first-child) {
  margin-left: 10px;
}

p, h1, h2, h3, h4, a, li, label, input[type="submit"], .__react_component_tooltip {
  color: #fff;
  font-family: Segoe UI, Geneva, Verdana, sans-serif;
}

h1, h2, h3, h4 {
  color: #0000;
  filter: drop-shadow(-5px 3px 5px #00000080);
  background-image: linear-gradient(to right, #cd59a4, #b45afd);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Montserrat, Segoe UI, Geneva, Verdana, sans-serif;
}

h1:not(#title > h1) {
  display: inline;
  position: relative;
}

h1:not(#title > h1):before {
  content: "";
  border-top: 1px solid #aeaeae;
  border-left: 1px solid #aeaeae;
  width: 10px;
  height: 10px;
  position: absolute;
  left: -7px;
}

h1:not(#title > h1):after {
  content: "";
  border-bottom: 1px solid #aeaeae;
  border-right: 1px solid #aeaeae;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: -7px;
}

.grey {
  color: #a3a3a3;
}

code {
  color: #ff6d6d;
}

.Toastify code {
  color: #d8d8d8;
}

a:not(#nav a, .button) {
  border-bottom: 2px dashed #aeaeae;
  text-decoration: none;
}

a:not(#nav a, .button):hover {
  border-color: #d8d8d8;
}

.generic-text {
  width: min(100% - 40px, 1000px);
  margin: 0 auto;
}

.generic-text:not(.no-padding) {
  padding: 0 20px 60px;
}

.generic-text.top-padding {
  padding-top: 45px;
}

.float-right {
  float: right;
}

.button {
  color: #fff;
  text-align: center;
  background-color: #2f2f2f;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  outline: none;
  min-width: 160px;
  margin: 10px 7px 0;
  padding: 8px 0;
  line-height: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:hover, .button:focus {
  border-color: #fff;
}

.button:active {
  transform: scale(.96);
}

.boxed {
  background-color: #252525;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 30px;
}

.svg-spacer {
  width: 100%;
  height: max(130px, 10vw);
  display: block;
}

.svg-spacer.flip {
  transform: rotate(180deg);
}

.sidebar-decoration {
  filter: drop-shadow(10px 10px 5px #00000080);
  pointer-events: none;
  animation: 3s cubic-bezier(.445, .05, .55, .95) infinite alternate hovering;
  position: absolute;
}

@media screen and (width <= 1400px) {
  .sidebar-decoration {
    display: none;
  }
}

@keyframes hovering {
  0% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0);
  }
}
/*# sourceMappingURL=index.13aea29a.css.map */
